import React, { Component } from "react";

require("./AboutContent.scss");
class AboutContent extends Component {
  render() {
    return (
      <div className="about-content">
        Started in 2009 by a small group of families in Southern California, New
        Beginnings Christian Fellowship is a volunteer-led organization that
        exists to serve indigenous missions in India. We invite you to pray,
        serve and give sacrificially with us.
      </div>
    );
  }
}

export default AboutContent;
