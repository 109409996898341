import React, { Component } from "react";
import Header from "../../Content/Header/Header";
import { Redirect } from "react-router-dom";
import { checkCookie } from "../../Utils/Utils";
import { Row, Col, Card } from "react-bootstrap";
require("./booth.scss");

class GameBooth extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="booth-container">
        <Header />
        <div className="booth-title">Game Booth</div>
        <Card.Img className="booth-image" src="/game-booth.jpg" />

        <Col>Description</Col>
        <Row>
          <Card.Title>Products on sale at this booth</Card.Title>
        </Row>
      </div>
    );
  }
}

export default GameBooth;
