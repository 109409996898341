import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { checkCookie } from "../../Utils/Utils";
import Header from "../../Content/Header/Header";
import OrderDetails from "../../Content/OrderDetails/OrderDetails";
import OrderSubmit from "../../Content/OrderSubmit/OrderSubmit";
import TokenMessage from "../../Content/TokenMessage/TokenMessage";

require("./CardDetailsPage.scss");

class CartDetailsPage extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="cart-container">
        <Header />
        <TokenMessage />
        <div className="cart-details-container">
          <OrderDetails />
        </div>
        <div className="order-submit-container">
          <OrderSubmit />
        </div>
      </div>
    );
  }
}

export default CartDetailsPage;
