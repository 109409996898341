import {
  LIST_PRODUCTS,
  FILTER_PRODUCTS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  RESET_CART,
  USER_ORDERS,
  FOOD_LIST,
  RAFFLE_LIST,
  ADD_FOOD_TO_CART,
  SNACKS_LIST,
  ADD_SNACKS_TO_CART,
  ETHNIC_LIST,
  ADD_ETHNIC_TO_CART,
  CRAFT_LIST,
  ADD_CRAFT_TO_CART,
  SERVICES_LIST,
  ADD_SERVICES_TO_CART,
  USER_DETAILS,
  SET_PRODUCT_MINIMUM_VALUE
} from "./actionTypes";

export const foodList = food => {
  return {
    type: FOOD_LIST,
    food
  };
};

export const raffleList = raffle => {
  return {
    type: RAFFLE_LIST,
    raffle
  };
};

export const snacksList = snacks => {
  return {
    type: SNACKS_LIST,
    snacks
  };
};

export const ethnicWearList = ethnicWear => {
  return {
    type: ETHNIC_LIST,
    ethnicWear
  };
};

export const craftList = crafts => {
  return {
    type: CRAFT_LIST,
    crafts
  };
};

export const servicesList = services => {
  return {
    type: SERVICES_LIST,
    services
  };
};

export const userDetails = user => {
  return {
    type: USER_DETAILS,
    user
  };
};

export const setProductMinimumValue = minValue => {
  return {
    type: SET_PRODUCT_MINIMUM_VALUE,
    minValue
  };
};

export const setProductsList = products => {
  return {
    type: LIST_PRODUCTS,
    products
  };
};

export const setOrderList = orders => {
  return {
    type: USER_ORDERS,
    orders
  };
};

export const setFilter = filter => {
  return {
    type: FILTER_PRODUCTS,
    filter
  };
};

export const addToCart = productId => {
  return {
    type: ADD_TO_CART,
    productId
  };
};

export const addFoodToCart = productId => {
  return {
    type: ADD_FOOD_TO_CART,
    productId
  };
};

export const addSnacksToCart = productId => {
  return {
    type: ADD_SNACKS_TO_CART,
    productId
  };
};

export const addEthnicWearToCart = productId => {
  return {
    type: ADD_ETHNIC_TO_CART,
    productId
  };
};

export const addCraftsToCart = productId => {
  return {
    type: ADD_CRAFT_TO_CART,
    productId
  };
};

export const addServicesToCart = productId => {
  return {
    type: ADD_SERVICES_TO_CART,
    productId
  };
};

export const removeFromCart = productId => {
  return {
    type: REMOVE_FROM_CART,
    productId
  };
};

export const resetCart = () => {
  return {
    type: RESET_CART
  };
};
