import React, { Component } from "react";
import { checkCookie } from "../../Utils/Utils";
import { Container, Card } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ethnicWearList, addEthnicWearToCart } from "../../../redux/action";
import { PRODUCTS_API } from "../../../constants/Constants";
import Header from "../../Content/Header/Header";
import PreviewCategories from "../../Content/PreviewCategories/PreviewCategories";

require("./EthnicWear.scss");

class EthnicWear extends Component {
  componentDidMount() {
    if (checkCookie("emailId")) {
      this.setState({ cookie: true });
    }

    if (this.props.ethnicWear.length === 0) {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleEthnicWearList(response.data.products);
        });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      cookie: false,
      pageName: "Ethnic Wear & Accessories"
    };
  }

  handleLike(productId) {
    axios.put(PRODUCTS_API + "/like/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleEthnicWearList(response.data.products);
        });
    });
  }

  handleDisLike(productId) {
    axios.put(PRODUCTS_API + "/dislike/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleEthnicWearList(response.data.products);
        });
    });
  }

  handleEthnicWearList(products) {
    this.props.ethnicWearList(products);
  }

  handleAddToCart(productId) {
    this.props.addEthnicWearToCart(productId);
  }

  render() {
    const ethnicWear = this.props.ethnicWear;
    return (
      <Container className="categories-page-container">
        <Header />
        <PreviewCategories categoryName={this.state.pageName} />
        <Container className="ethnic-container">
          {Object.keys(ethnicWear).map(key => (
            <Card className="ethnic-card" key={key}>
              <Card.Img
                className="ethnic-image"
                src={ethnicWear[key].imageName}
              />
              <Card.Body className="ethnic-card-body">
                <Accordion>
                  <AccordionSummary className="ethnic-title">
                    {ethnicWear[key].productName} <ExpandMoreIcon />
                  </AccordionSummary>
                  <AccordionDetails className="ethnic-description">
                    {ethnicWear[key].description}
                  </AccordionDetails>
                </Accordion>
                {/*<Card.Text>
                  <span className="ethnic-price">{ethnicWear[key].value} </span>
                  <span>tokens</span>
                  <span className="ethnic-quantity">
                    {ethnicWear[key].quantity}{" "}
                  </span>
                  <span> item(s) left</span>
                </Card.Text>
                <Card.Text
                  className="ethnic-alert"
                  hidden={!(ethnicWear[key].count >= ethnicWear[key].maxItems)}
                >
                  You can only add {ethnicWear[key].maxItems} items of the same
                  type
                </Card.Text>
                <Button
                  className="ethnic-button"
                  disabled={ethnicWear[key].quantity === 0}
                  onClick={() => {
                    this.handleAddToCart(ethnicWear[key].productId);
                  }}
                  block
                >
                  Add To Cart
                </Button>*/}
                <div className="ethnic-feedback">
                  {ethnicWear[key].likes} {"    "}
                  <IconButton
                    onClick={() => {
                      this.handleLike(ethnicWear[key].productId);
                    }}
                  >
                    <ThumbUpOutlinedIcon />
                  </IconButton>{" "}
                  {ethnicWear[key].dislikes}
                  <IconButton
                    onClick={() => {
                      this.handleDisLike(ethnicWear[key].productId);
                    }}
                  >
                    <ThumbDownOutlinedIcon />
                  </IconButton>
                </div>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    ethnicWear: state ? state.ethnicWear : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ethnicWearList: ethnicWear => {
      dispatch(ethnicWearList(ethnicWear));
    },
    addEthnicWearToCart: productId => {
      dispatch(addEthnicWearToCart(productId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(EthnicWear);
