import React, { Component } from "react";
import FaqComponent from "react-faq-component";
import { Redirect } from "react-router-dom";
import { checkCookie } from "../../Utils/Utils";
import Header from "../../Content/Header/Header";

require("./FAQPage.scss");

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      title: "What is NBCF & Resonate?",
      content:
        "New Beginnings Christian Fellowship started in 2009 is a gathering of people in Southern California who love Jesus and have a passion to mobilize resources to support projects/missions in India. We support close to 67 indigenous projects/missions. <br/> <br/> Resonate is an annual fundraiser that is fully led by volunteers. 100% of your offerings go to the project/mission field."
    },
    {
      title: "What is the recommended donation for each token?",
      content:
        "Recommended donation is $2 per token. Please call or text us at <a href='tel:+19496566287'>949-656-6287</a> if you need additional clarifications."
    },
    {
      title: "How do I participate in Silent Auction?",
      content:
        "To participate in Silent Auction <ul><li>Click on Silent Auction link </li><li>This will take you to the list of items available in the Silent auction</li><li>Choose the item you want to bid on and place your bid</li><li>You can add items to the favorites by clicking the heart icon and see them on the top of your list.</li><li>You can make repeated entries to outbid others</li><li>Click the refresh button to see the latest bids.</li><li>The silent auction results will be announced at the end of the Resonate program on May 5<sup>th</sup>. </li></ul>"
    },
    {
      title: "What can I order on the website?",
      content:
        "We encourage everyone to join us on May 5<sup>th</sup> in-person. You can order a very limited number of items/services on the website. <br/> We will not require you to make immediate payments when you order on the website or participate in the silent auction.  You can donate during the event."
    },
    {
      title: "How and when do I make payments?",
      content:
        "You are not required to make payments when you place the order on the website. Once you complete your order, you will receive an email with the items ordered. <br/><br/>You can purchase the tokens in-person during the Resonate event and collect the items."
    },
    {
      title: "How can I make additional donations?",
      content:
        "Many of you just make donations irrespective of what you order. You can continue to do so using the below options <ul><li>Click on the <b><a href='https://secure.ncfgiving.com/GXDonateNow?id=a0Ui0000007zz5OEAQ' target='_blank'>donate</a></b> button on the website</li><li>You can write a check in favor of 'NCF-NBCF Fund'</li><li>Use Venmo <b>(@NBCF-SoCal)</b> to make payment <ul><li>Venmo donations will be treated as cash donations</li></ul></li></ul>"
    },
    {
      title:
        "What if I have additional questions or need additional clarifications?",
      content:
        "Please Call/Text us at <a href='tel:+19496566287'>949-656-6287</a>."
    }
  ]
};

class FAQPage extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="faq-container">
        <Header />
        <FaqComponent data={data} />
      </div>
    );
  }
}

export default FAQPage;
