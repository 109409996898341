import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Registration from "../components/Page/RegistrationPage/RegistrationPage";
import HomePage from "../components/Page/HomePage/HomePage";
import AuctionsPage from "../components/Page/AuctionsPage/AuctionsPage";
import FAQPage from "../components/Page/FAQPage/FAQPage";
import CartDetailsPage from "../components/Page/CartDetailsPage/CartDetailsPage";
import ProfilePage from "../components/Page/ProfilePage/ProfilePage";
import OrderList from "../components/Page/OrderListPage/OrderListPage";
import AuctionDetails from "../components/Page/AuctionDetails/AuctionDetails";
import Food from "../components/Page/Food/Food";
import Raffle from "../components/Page/Raffle/Raffle";
import Snacks from "../components/Page/Snacks/Snacks";
import EthnicWear from "../components/Page/EthnicWear/EthnicWear";
import Crafts from "../components/Page/Crafts/Crafts";
import Services from "../components/Page/Services/Services";
import Tokens from "../components/Page/Tokens/Tokens";
import InteractiveMap from "../components/Page/InteractiveMap/InteractiveMap";
import AdornAdore from "../components/Page/InteractiveMap/AdornAdore";
import BiriyaniBazaar from "../components/Page/InteractiveMap/BiriyaniBazaar";
import ChaatCorner from "../components/Page/InteractiveMap/ChaatCorner";
import CoffeeCorner from "../components/Page/InteractiveMap/CoffeeCorner";
import CookieCorner from "../components/Page/InteractiveMap/CookieCorner";
import CottonCandy from "../components/Page/InteractiveMap/CottonCandy";
import DazlingDesigns from "../components/Page/InteractiveMap/DazlingDesigns";
import DelightfulDrinks from "../components/Page/InteractiveMap/DelightfulDrinks";
import EthnicElegance from "../components/Page/InteractiveMap/EthnicElegance";
import GameBooth from "../components/Page/InteractiveMap/GameBooth";
import KidzKorner from "../components/Page/InteractiveMap/KidzKorner";
import ParottaKadai from "../components/Page/InteractiveMap/ParottaKadai";
import SnackShack from "../components/Page/InteractiveMap/SnackShack";
import TeaTime from "../components/Page/InteractiveMap/TeaTime";
import TiffinTable from "../components/Page/InteractiveMap/TiffinTable";

const AppRouter = () => (
  <BrowserRouter>
    <Switch>
      <Route component={HomePage} path="/" exact={true} />
      <Route component={Registration} path="/registration" />
      <Route component={AuctionsPage} path="/home" />
      <Route component={FAQPage} path="/faq" />
      <Route component={ProfilePage} path="/profile" />
      <Route component={CartDetailsPage} path="/cart" />
      <Route component={OrderList} path="/orders" />
      <Route component={AuctionDetails} path="/auctiondetails" />
      <Route component={Food} path="/food" />
      <Route component={Raffle} path="/raffle" />
      <Route component={Snacks} path="/snacks" />
      <Route component={EthnicWear} path="/ethnic-wear" />
      <Route component={Crafts} path="/crafts" />
      <Route component={Services} path="/services" />
      <Route component={Tokens} path="/tokens" />
      <Route component={InteractiveMap} path="/map" />
      <Route component={CottonCandy} path="/cotton-candy" />
      <Route component={CookieCorner} path="/cookie-couture" />
      <Route component={ChaatCorner} path="/chaat-cart" />
      <Route component={DelightfulDrinks} path="/delightful-drinks" />
      <Route component={SnackShack} path="/snack-shack" />
      <Route component={BiriyaniBazaar} path="/biriyani-bazar" />
      <Route component={ParottaKadai} path="/parotta-kadai" />
      <Route component={TiffinTable} path="/tiffin-table" />
      <Route component={CoffeeCorner} path="/coffee-corner" />
      <Route component={KidzKorner} path="/kidz-korner" />
      <Route component={AdornAdore} path="/adorn-adore" />
      <Route component={EthnicElegance} path="/ethnic-elegance" />
      <Route component={GameBooth} path="/game-booth" />
      <Route component={DazlingDesigns} path="/dazzling-designs" />
      <Route component={TeaTime} path="/tea-time" />
    </Switch>
  </BrowserRouter>
);

export default AppRouter;
