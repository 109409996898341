import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Button, Container, Alert } from "react-bootstrap";
import axios from "axios";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  snacksList,
  addSnacksToCart,
  setOrderList
} from "../../../redux/action";
import { ORDER_API, PRODUCTS_API } from "../../../constants/Constants";
import { getCookie, checkCookie } from "../../Utils/Utils";
import Header from "../../Content/Header/Header";
import PreviewCategories from "../../Content/PreviewCategories/PreviewCategories";
import Banner from "../../Content/Banner/Banner";

require("./Snacks.scss");

class Snacks extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    } else {
      this.getUserDetails(getCookie("emailId"));
      if (this.props.snacks.length === 0) {
        axios
          .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
          .then(response => {
            this.handleSnacksList(response.data.products);
          });
      }
    }
  }

  handleSnacksList(products) {
    this.props.snacksList(products);
  }

  handleAddToCart(productId) {
    this.props.addSnacksToCart(productId);
  }

  getUserDetails(email) {
    if (this.props.orders === undefined) {
      axios.get(ORDER_API + "?userId=" + getCookie("userId")).then(response => {
        this.props.setOrderList(response.data.orderList);
      });
    }
  }

  handleLike(productId) {
    axios.put(PRODUCTS_API + "/like/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleSnacksList(response.data.products);
        });
    });
  }

  handleDisLike(productId) {
    axios.put(PRODUCTS_API + "/dislike/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleSnacksList(response.data.products);
        });
    });
  }

  disableButton(productId, maxItems) {
    let disable = false;
    if (this.props.orders !== undefined) {
      let product = this.props.orders.find(
        order => order.productId === productId
      );
      if (product !== undefined) {
        if (product.quantity >= 2 * Number(maxItems)) {
          disable = true;
        }
      }
    }
    return disable;
  }

  constructor(props) {
    super(props);

    this.state = {
      cookie: false,
      pageName: "Snacks"
    };
  }

  render() {
    const snacks = this.props.snacks;
    return (
      <Container className="categories-page-container">
        <Header />
        <Banner />
        <PreviewCategories categoryName={this.state.pageName} />
        <Alert className="alert-message" key="primary" variant="primary">
          The items listed below are available in person on May 5<sup>th</sup>{" "}
          at Resonate.
        </Alert>
        <Container className="snacks-container">
          {Object.keys(snacks).map(key => (
            <Card className="snacks-card" key={key}>
              <Card.Img className="snacks-image" src={snacks[key].imageName} />
              <Card.Body className="snacks-card-body">
                <Accordion>
                  <AccordionSummary className="snacks-title">
                    {snacks[key].productName} <ExpandMoreIcon />
                  </AccordionSummary>
                  <AccordionDetails className="snacks-description">
                    {snacks[key].description}
                  </AccordionDetails>
                </Accordion>
                {snacks[key].quantity !== 0 && (
                  <Card.Text>
                    <span className="snacks-price">{snacks[key].value} </span>
                    <span>tokens</span>
                    <span className="snacks-quantity">
                      {snacks[key].quantity}{" "}
                    </span>
                    <span> item(s) left</span>
                  </Card.Text>
                )}
                <Card.Text
                  className="snacks-alert"
                  hidden={!(snacks[key].count >= snacks[key].maxItems)}
                >
                  You can only add {snacks[key].maxItems} items of the same type
                </Card.Text>
                <Card.Text
                  className="snacks-alert"
                  hidden={
                    !this.disableButton(
                      snacks[key].productId,
                      snacks[key].maxItems
                    )
                  }
                >
                  You reached the maximum limit for this item.
                </Card.Text>
                {snacks[key].quantity !== 0 && (
                  <Button
                    className="snacks-button"
                    disabled={
                      snacks[key].quantity === 0 ||
                      snacks[key].count >= snacks[key].maxItems ||
                      this.disableButton(
                        snacks[key].productId,
                        snacks[key].maxItems
                      )
                    }
                    onClick={() => {
                      this.handleAddToCart(snacks[key].productId);
                    }}
                    block
                  >
                    Add To Cart
                  </Button>
                )}
                <Card.Text className="snacks-feedback">
                  <IconButton
                    onClick={() => {
                      this.handleLike(snacks[key].productId);
                    }}
                  >
                    <ThumbUpOutlinedIcon />
                  </IconButton>{" "}
                  {snacks[key].likes} {"    "}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    snacks: state ? state.snacks : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    snacksList: snacks => {
      dispatch(snacksList(snacks));
    },
    addSnacksToCart: productId => {
      dispatch(addSnacksToCart(productId));
    },
    setOrderList: orders => {
      dispatch(setOrderList(orders));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(Snacks);
