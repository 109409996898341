import React, { Component } from "react";
import GuestHeader from "./GuestHeader/GuestHeader";
import GuestContent from "./GuestContent/GuestContent";

class GuestHomePage extends Component {
  render() {
    return (
      <div>
        <GuestHeader />
        <GuestContent />
      </div>
    );
  }
}

export default GuestHomePage;
