import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { removeFromCart } from "../../../redux/action";

require("./OrderDetails.scss");

class OrderDetails extends Component {
  handleRemoveCart(productId) {
    this.props.removeFromCart(productId);
  }

  render() {
    const cartItems = this.props.cartItems;
    return (
      <Container className="order-details-container">
        {Object.keys(cartItems).map(key => (
          <Card className="cart-card" key={key}>
            <Card.Img
              className="cart-image"
              style={{
                backgroundImage: `url(${cartItems[key].imageName})`,
                backgroundSize: "cover"
              }}
            />
            <Card.Body>
              <Card.Title>{cartItems[key].productName}</Card.Title>
              <Card.Text>{cartItems[key].description}</Card.Text>
              <Card.Text>
                Number of Item(s): <strong>{cartItems[key].count}</strong>
              </Card.Text>
              <Card.Text>
                <span className="itemPrice">{cartItems[key].totalValue}</span>
                <span> token(s)</span>
              </Card.Text>
              <Button
                onClick={() => {
                  this.handleRemoveCart(cartItems[key].productId);
                }}
                block
              >
                Remove Item
              </Button>
            </Card.Body>
          </Card>
        ))}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartItems: state.cart,
    totalToken: state.totalValue
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeFromCart: productId => {
      dispatch(removeFromCart(productId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(OrderDetails);
