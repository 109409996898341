import React, { Component } from "react";

require("./GuestHeader.scss");
class GuestHeader extends Component {
  render() {
    return (
      <div className="guest-header-container">
        {" "}
        <img
          className="logo"
          src="/new-beginnings-christian-fellowship-logo.png"
          alt=""
        />
      </div>
    );
  }
}

export default GuestHeader;
