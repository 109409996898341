import React, { Component } from "react";
import Marquee from "react-fast-marquee";
require("./TokenMessage.scss");

class TokenMessage extends Component {
  render() {
    return (
      <Marquee speed="90">
        <div className="token-details-home">
          Recommended donation is $2 per token.
        </div>
      </Marquee>
    );
  }
}

export default TokenMessage;
