import React, { Component } from "react";
import Header from "../../Content/Header/Header";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Container } from "@material-ui/core";
import Table from "react-bootstrap/Table";

import { checkCookie, getCookie } from "../../Utils/Utils";
import { ORDER_API } from "../../../constants/Constants";

require("./OrderListPage.scss");
class OrderList extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
    axios.get(ORDER_API + "?userId=" + getCookie("userId")).then(response => {
      this.setState({ orderList: response.data.orderList });
      this.setState({ totalValue: response.data.totalValue });
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      orderList: [],
      totalValue: 0
    };
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const orderList = this.state.orderList;
    const totalValue = this.state.totalValue;
    return (
      <div className="orderlist-page-container">
        <Header />
        <Container className="order-container">
          <div className="order-title">Your Order Details</div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>Product Image</th>
                <th>Quantity</th>
                <th>Tokens</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(orderList).map(key => (
                <tr key={key}>
                  <td>{Number(key) + 1}</td>
                  <td style={{ textAlign: "justify" }}>
                    {orderList[key].productName}
                  </td>
                  <img
                    src={orderList[key].productImage}
                    alt="#"
                    width="200"
                  ></img>
                  <td> {orderList[key].quantity}</td>
                  <td>{orderList[key].productValue}</td>
                </tr>
              ))}
              <tr>
                <th colSpan="4">Total Tokens</th>
                <th>{totalValue}</th>
              </tr>
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}

export default OrderList;
