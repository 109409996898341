import Cookies from "js-cookie";

export function isStringEmpty(value) {
  return value === "" ? true : false;
}

export function isValidString(value) {
  const regexp = /[0-9a-zA-Z ]/;
  return regexp.test(value);
}

export function isValidName(value) {
  const regexp = /[a-zA-Z ]$/;
  return regexp.test(value);
}

export function isValidChar(value) {
  const regexp = /[^a-zA-Z]/;
  return regexp.test(value);
}

export function isValidZipcode(value) {
  const regexp = /^\d{5}$/;
  return regexp.test(value);
}

export function isValidEmail(value) {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(value);
}

export function isValidPhoneNumber(value) {
  const regexp = /^\d{10}$/;
  return regexp.test(value);
}

export function deleteUserCookie() {
  Cookies.remove("emailId");
  Cookies.remove("userId");
}

export function checkCookie(name) {
  return Cookies.get(name) ? true : false;
}

export function getCookie(name) {
  return Cookies.get(name);
}
