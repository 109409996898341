import React, { Component } from "react";
require("./Banner.scss");

class Banner extends Component {
  render() {
    return (
      <div className="banner-container">
        <div className="banner-heading">
          RESONATE '24 @ FULLERTON FREE CHURCH
        </div>
        <div className="banner-content">
          May 5th, 2024 @ 4 PM <br /> 2904 N Brea Blvd, #190, Fullerton, CA -
          92835
          <br />
        </div>
        <div className="banner-endnote">
          Join us in person this year for an evening of Inspiration, Fellowship
          & Fun. Part of the event is outdoors.
        </div>
      </div>
    );
  }
}

export default Banner;
