import React, { Component } from "react";
import axios from "axios";
import { checkCookie, getCookie } from "../../Utils/Utils";
import {
  isStringEmpty,
  isValidString,
  isValidZipcode,
  isValidPhoneNumber
} from "../../Utils/Utils";
import { USER_EMAIL_API, USER_API } from "../../../constants/Constants";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
require("./ProfileDetails.scss");

class ProfileDetails extends Component {
  componentDidMount() {
    if (checkCookie("emailId")) {
      let email = getCookie("emailId");
      this.getUserDetails(email);
      this.setState({ emailAddress: email });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      user: "",
      emailAddress: "",
      errormessage: {
        firstName: "First Name cannot be empty  and can be letters or numbers",
        lastName: "Last Name cannot be empty  and can be letters or numbers",
        contactNumber:
          "Contact Number Should be 10 digits without Special Characters",
        emailAddress: "Enter a valid Email address",
        address: "Address field cannot be empty",
        city: "Cannot be Empty",
        state: "Cannot be Empty",
        zipcode: "Invalid Zip code"
      },
      firstNameValid: true,
      lastNameValid: true,
      contactNumberValid: true,
      emailAddressValid: true,
      addressValid: true,
      cityValid: true,
      stateValid: true,
      zipcodeValid: true,
      edit: false,
      buttonText: "Edit"
    };

    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handleContactNumber = this.handleContactNumber.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleZipcode = this.handleZipcode.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    let value = this.state;
    if (
      value.firstNameValid &&
      value.lastNameValid &&
      value.contactNumberValid &&
      value.emailAddressValid &&
      value.addressValid &&
      value.cityValid &&
      value.stateValid &&
      value.zipcodeValid
    ) {
      let user = value.user;
      axios
        .post(USER_API, user, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          if (response.status === 201) {
            this.setState({ buttonText: "Edit", edit: false });
            this.getUserDetails(this.state.emailAddress);
          }
        });
    }
  }

  handleFirstName(e) {
    let user = this.state.user;
    user.firstName = e.target.value;
    this.setState({ user });
    if (isStringEmpty(user.firstName) || isValidString(user.firstName)) {
      this.setState({ firstNameValid: false });
    } else {
      this.setState({ firstNameValid: true });
    }
  }

  handleLastName(e) {
    let user = this.state.user;
    user.lastName = e.target.value;
    this.setState({ user });
    if (isStringEmpty(user.lastName) || isValidString(user.lastName)) {
      this.setState({ lastNameValid: false });
    } else {
      this.setState({ lastNameValid: true });
    }
  }

  handleAddress(e) {
    let user = this.state.user;
    user.address = e.target.value;
    this.setState({ user });
    if (isStringEmpty(user.address)) {
      this.setState({ addressValid: false });
    } else {
      this.setState({ addressValid: true });
    }
  }

  handleContactNumber(e) {
    let user = this.state.user;
    user.contactNumber = e.target.value;
    this.setState({ user });
    if (isValidPhoneNumber(user.contactNumber)) {
      this.setState({ contactNumberValid: true });
    } else {
      this.setState({ contactNumberValid: false });
    }
  }

  handleCity(e) {
    let user = this.state.user;
    user.city = e.target.value;
    this.setState({ user });
    if (isStringEmpty(user.city)) {
      this.setState({ cityValid: false });
    } else {
      this.setState({ cityValid: true });
    }
  }

  handleState(e) {
    let user = this.state.user;
    user.state = e.target.value;
    this.setState({ user });
    if (isStringEmpty(user.state)) {
      this.setState({ stateValid: false });
    } else {
      this.setState({ stateValid: true });
    }
  }

  handleZipcode(e) {
    let user = this.state.user;
    user.zipcode = e.target.value;
    this.setState({ user });
    if (isStringEmpty(user.zipcode) || !isValidZipcode(user.zipcode)) {
      this.setState({ zipcodeValid: false });
    } else {
      this.setState({ zipcodeValid: true });
    }
  }

  handleEdit(e) {
    this.setState({ buttonText: "Save", edit: true });
  }

  handleCancel(e) {
    this.setState({ buttonText: "Edit", edit: false });
    this.getUserDetails(this.state.emailAddress);
  }

  getUserDetails(email) {
    axios.get(USER_EMAIL_API + email).then(response => {
      this.setState({ user: response.data.userDetails });
    });
  }

  render() {
    return (
      <div className="profile-form-container">
        <div className="profile-title">USER DETAILS</div>
        <Form className="profile-form">
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your First Name"
              name="firstName"
              value={this.state.user.firstName}
              onChange={this.handleFirstName}
              isInvalid={!this.state.firstNameValid}
              readOnly={this.state.edit ? false : true}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your Last Name"
              name="lastName"
              value={this.state.user.lastName}
              onChange={this.handleLastName}
              isInvalid={!this.state.lastNameValid}
              readOnly={this.state.edit ? false : true}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your Contact Number"
              name="phone"
              onChange={this.handleContactNumber}
              value={this.state.user.contactNumber}
              isInvalid={!this.state.contactNumberValid}
              readOnly={this.state.edit ? false : true}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.contactNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={this.state.user.emailAddress}
              readOnly
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.emailAddress}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your resident address"
              name="address"
              onChange={this.handleAddress}
              value={this.state.user.address}
              isInvalid={!this.state.addressValid}
              readOnly={this.state.edit ? false : true}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.address}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter city"
                  name="city"
                  onChange={this.handleCity}
                  value={this.state.user.city}
                  isInvalid={!this.state.cityValid}
                  readOnly={this.state.edit ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errormessage.city}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter state"
                  name="state"
                  onChange={this.handleState}
                  value={this.state.user.state}
                  isInvalid={!this.state.stateValid}
                  readOnly={this.state.edit ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errormessage.state}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Zip code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter zip code"
                  name="zip code"
                  value={this.state.user.zipcode}
                  onChange={this.handleZipcode}
                  isInvalid={!this.state.zipcodeValid}
                  readOnly={this.state.edit ? false : true}
                />
                <Form.Control.Feedback type="invalid">
                  {this.state.errormessage.zipcode}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <Button
              className="registration-confirm-button"
              variant="primary"
              onClick={!this.state.edit ? this.handleEdit : this.handleSubmit}
              block
            >
              {this.state.buttonText}
            </Button>
          </Col>

          {this.state.edit ? (
            <Col>
              <Button
                className="registration-confirm-button"
                variant="secondary"
                onClick={this.handleCancel}
                block
              >
                Cancel
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </div>
    );
  }
}

export default ProfileDetails;
