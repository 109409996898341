import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Card, Button, Container } from "react-bootstrap";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import IconButton from "@mui/material/IconButton";

import { servicesList, addServicesToCart } from "../../../redux/action";
import { PRODUCTS_API } from "../../../constants/Constants";
import { checkCookie } from "../../Utils/Utils";
import Header from "../../Content/Header/Header";
import Banner from "../../Content/Banner/Banner";

require("./Services.scss");

class Services extends Component {
  componentDidMount() {
    if (checkCookie("emailId")) {
      this.setState({ cookie: true });
    }

    if (this.props.services.length === 0) {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleServicesList(response.data.products);
        });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      cookie: false,
      pageName: "Services & Games"
    };
  }

  handleLike(productId) {
    axios.put(PRODUCTS_API + "/like/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleServicesList(response.data.products);
        });
    });
  }

  handleDisLike(productId) {
    axios.put(PRODUCTS_API + "/dislike/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleServicesList(response.data.products);
        });
    });
  }

  handleServicesList(products) {
    this.props.servicesList(products);
  }

  handleAddToCart(productId) {
    this.props.addServicesToCart(productId);
  }

  render() {
    const services = this.props.services;
    return (
      <Container className="categories-page-container">
        <Header />
        <Banner />
        <Container className="services-container">
          {Object.keys(services).map(key => (
            <Card className="services-card" key={key}>
              <Card.Img
                className="services-image"
                src={services[key].imageName}
              />
              <Card.Body className="services-card-body">
                <Accordion>
                  <AccordionSummary className="services-title">
                    {services[key].productName} <ExpandMoreIcon />
                  </AccordionSummary>
                  <AccordionDetails className="services-description">
                    {services[key].description}
                  </AccordionDetails>
                </Accordion>

                <Card.Text>
                  <span className="services-price">{services[key].value} </span>
                  <span>tokens</span>
                  <span className="services-quantity">
                    {services[key].quantity}{" "}
                  </span>
                  <span> item(s) left</span>
                </Card.Text>
                <Card.Text
                  className="services-alert"
                  hidden={!(services[key].count >= services[key].maxItems)}
                >
                  You can only add {services[key].maxItems} items of the same
                  type
                </Card.Text>
                <Card.Text className="services-feedback">
                  <IconButton
                    onClick={() => {
                      this.handleLike(services[key].productId);
                    }}
                  >
                    <ThumbUpOutlinedIcon />
                  </IconButton>{" "}
                  {services[key].likes} {"    "}
                </Card.Text>
                <Button
                  className="services-button"
                  disabled={services[key].quantity === 0}
                  onClick={() => {
                    this.handleAddToCart(services[key].productId);
                  }}
                  block
                >
                  Add To Cart
                </Button>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    services: state ? state.services : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    servicesList: services => {
      dispatch(servicesList(services));
    },
    addServicesToCart: productId => {
      dispatch(addServicesToCart(productId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(Services);
