import {
  LIST_PRODUCTS,
  FILTER_PRODUCTS,
  ADD_TO_CART,
  ADD_FOOD_TO_CART,
  ADD_SNACKS_TO_CART,
  ADD_ETHNIC_TO_CART,
  ADD_CRAFT_TO_CART,
  ADD_SERVICES_TO_CART,
  REMOVE_FROM_CART,
  RESET_CART,
  USER_ORDERS,
  FOOD_LIST,
  SNACKS_LIST,
  ETHNIC_LIST,
  CRAFT_LIST,
  SERVICES_LIST,
  USER_DETAILS,
  SET_PRODUCT_MINIMUM_VALUE,
  RAFFLE_LIST
} from "./actionTypes";

const initState = {
  products: [],
  food: [],
  raffle: [],
  snacks: [],
  ethnicWear: [],
  crafts: [],
  services: [],
  user: null,
  cart: [],
  orders: [],
  cartCount: 0,
  totalValue: 0,
  minValue: 0,
  filter: "All Items",
  disclaimer: "The images on the site are for illustrative purposes only.",
  filterProducts: [],
  menu: ["Auctions", "FAQ'S", "Contact Us"]
};
const reducer = (state = initState, action) => {
  if (action.type === LIST_PRODUCTS) {
    state.products = action.products;
    if (state.filter !== "All Items") {
      state.filterProducts = state.products.filter(
        product => state.filter === product.category
      );
    } else {
      state.filterProducts = action.products;
    }

    return {
      ...state
    };
  }

  if (action.type === FOOD_LIST) {
    state.food = action.food;

    return {
      ...state
    };
  }

  if (action.type === RAFFLE_LIST) {
    state.raffle = action.raffle;

    return {
      ...state
    };
  }

  if (action.type === SNACKS_LIST) {
    state.snacks = action.snacks;

    return {
      ...state
    };
  }

  if (action.type === ETHNIC_LIST) {
    state.ethnicWear = action.ethnicWear;

    return {
      ...state
    };
  }

  if (action.type === CRAFT_LIST) {
    state.crafts = action.crafts;

    return {
      ...state
    };
  }

  if (action.type === SERVICES_LIST) {
    state.services = action.services;

    return {
      ...state
    };
  }

  if (action.type === USER_DETAILS) {
    state.user = action.user;
    return {
      ...state
    };
  }

  if (action.type === USER_ORDERS) {
    state.orders = action.orders;
    return {
      ...state
    };
  }

  if (action.type === SET_PRODUCT_MINIMUM_VALUE) {
    state.minValue = action.minValue;
    return {
      ...state
    };
  }

  if (action.type === FILTER_PRODUCTS) {
    state.filter = action.filter;
    if (action.filter === "All Items") {
      state.filterProducts = state.products;
      state.disclaimer =
        "The image on the site is for illustrative purposes only.";
    } else {
      if (action.filter === " Food" || action.filter === " Snacks") {
        state.disclaimer =
          "The image on the site is for illustrative purposes only.";
      } else if (
        action.filter === "Ethnic Wear & Accessories" ||
        action.filter === "Crafts & More"
      ) {
        state.disclaimer =
          "The image is of the actual product. Colors in the picture may vary.";
      } else if (action.filter === "From the Mission Field") {
        state.disclaimer =
          "The image is of the actual product. The colors in the picture may vary. Delivery dates might differ.";
      } else if (action.filter === "Home, Health & Hobbies") {
        state.disclaimer =
          "Most images are of actual products. The colors in the picture may vary.";
      } else {
        state.disclaimer = "";
      }
      state.filterProducts = state.products.filter(
        product => action.filter === product.category
      );
    }
    return {
      ...state
    };
  }

  if (action.type === ADD_FOOD_TO_CART) {
    state.cartCount += 1;
    let foodProduct = state.food.find(
      product => product.productId === action.productId
    );

    let itemInCart = state.cart.find(
      product => product.productId === action.productId
    );

    if (!itemInCart) {
      foodProduct.count = 1;
      foodProduct.quantity -= 1;
      foodProduct.totalValue = foodProduct.value;
      state.totalValue = state.totalValue + foodProduct.value;
      state.cart = [...state.cart, foodProduct];
    } else {
      foodProduct.count += 1;
      foodProduct.quantity -= 1;
      foodProduct.totalValue += foodProduct.value;
      state.totalValue = state.totalValue + foodProduct.value;
    }

    return {
      ...state
    };
  }

  if (action.type === ADD_SNACKS_TO_CART) {
    state.cartCount += 1;
    let snackProduct = state.snacks.find(
      product => product.productId === action.productId
    );

    let itemInCart = state.cart.find(
      product => product.productId === action.productId
    );

    if (!itemInCart) {
      snackProduct.count = 1;
      snackProduct.quantity -= 1;
      snackProduct.totalValue = snackProduct.value;
      state.totalValue = state.totalValue + snackProduct.value;
      state.cart = [...state.cart, snackProduct];
    } else {
      snackProduct.count += 1;
      snackProduct.quantity -= 1;
      snackProduct.totalValue += snackProduct.value;
      state.totalValue = state.totalValue + snackProduct.value;
    }

    return {
      ...state
    };
  }

  if (action.type === ADD_ETHNIC_TO_CART) {
    state.cartCount += 1;
    let ethnicWearProduct = state.ethnicWear.find(
      product => product.productId === action.productId
    );

    let itemInCart = state.cart.find(
      product => product.productId === action.productId
    );

    if (!itemInCart) {
      ethnicWearProduct.count = 1;
      ethnicWearProduct.quantity -= 1;
      ethnicWearProduct.totalValue = ethnicWearProduct.value;
      state.totalValue = state.totalValue + ethnicWearProduct.value;
      state.cart = [...state.cart, ethnicWearProduct];
    } else {
      ethnicWearProduct.count += 1;
      ethnicWearProduct.quantity -= 1;
      ethnicWearProduct.totalValue += ethnicWearProduct.value;
      state.totalValue = state.totalValue + ethnicWearProduct.value;
    }

    return {
      ...state
    };
  }

  if (action.type === ADD_CRAFT_TO_CART) {
    state.cartCount += 1;
    let craftProduct = state.crafts.find(
      product => product.productId === action.productId
    );

    let itemInCart = state.cart.find(
      product => product.productId === action.productId
    );

    if (!itemInCart) {
      craftProduct.count = 1;
      craftProduct.quantity -= 1;
      craftProduct.totalValue = craftProduct.value;
      state.totalValue = state.totalValue + craftProduct.value;
      state.cart = [...state.cart, craftProduct];
    } else {
      craftProduct.count += 1;
      craftProduct.quantity -= 1;
      craftProduct.totalValue += craftProduct.value;
      state.totalValue = state.totalValue + craftProduct.value;
    }

    return {
      ...state
    };
  }

  if (action.type === ADD_SERVICES_TO_CART) {
    state.cartCount += 1;
    let serviceProduct = state.services.find(
      product => product.productId === action.productId
    );

    let itemInCart = state.cart.find(
      product => product.productId === action.productId
    );

    if (!itemInCart) {
      serviceProduct.count = 1;
      serviceProduct.quantity -= 1;
      serviceProduct.totalValue = serviceProduct.value;
      state.totalValue = state.totalValue + serviceProduct.value;
      state.cart = [...state.cart, serviceProduct];
    } else {
      serviceProduct.count += 1;
      serviceProduct.quantity -= 1;
      serviceProduct.totalValue += serviceProduct.value;
      state.totalValue = state.totalValue + serviceProduct.value;
    }

    return {
      ...state
    };
  }

  if (action.type === ADD_TO_CART) {
    state.cartCount += 1;
    let cartProduct = state.products.find(
      product => product.productId === action.productId
    );
    let cartInProduct = state.cart.find(
      product => product.productId === action.productId
    );
    let orderItem = state.orders.find(
      order => order.productId === action.productId
    );

    if (!cartInProduct) {
      cartProduct.count = 1;
      cartProduct.quantity -= 1;
      cartProduct.totalValue = cartProduct.value;
      state.totalValue = state.totalValue + cartProduct.value;
      state.cart = [...state.cart, cartProduct];
    } else {
      cartProduct.count += 1;
      cartProduct.quantity -= 1;
      cartProduct.totalValue += cartProduct.value;
      state.totalValue = state.totalValue + cartProduct.value;
    }
    if (orderItem !== undefined) {
      orderItem.quantity += 1;
      state.orders = [...state.orders];
    }

    return {
      ...state
    };
  }

  if (action.type === REMOVE_FROM_CART) {
    let productToRemove = state.cart.find(
      product => action.productId === product.productId
    );

    //update cart
    state.cart = state.cart.filter(
      product => action.productId !== product.productId
    );

    state.totalValue = state.totalValue - productToRemove.totalValue;
    state.cartCount = state.cartCount - productToRemove.count;
    // update available items count

    if (productToRemove.category === "Food") {
      state.food.find(
        product => action.productId === product.productId
      ).quantity += productToRemove.count;

      state.food.find(
        product => action.productId === product.productId
      ).count -= productToRemove.count;
    }

    if (productToRemove.category === "Snacks") {
      state.snacks.find(
        product => action.productId === product.productId
      ).quantity += productToRemove.count;

      state.snacks.find(
        product => action.productId === product.productId
      ).count -= productToRemove.count;
    }

    if (productToRemove.category === "Ethnic Wear & Accessories") {
      state.ethnicWear.find(
        product => action.productId === product.productId
      ).quantity += productToRemove.count;

      state.ethnicWear.find(
        product => action.productId === product.productId
      ).count -= productToRemove.count;
    }

    if (productToRemove.category === "Crafts & More") {
      state.crafts.find(
        product => action.productId === product.productId
      ).quantity += productToRemove.count;

      state.crafts.find(
        product => action.productId === product.productId
      ).count -= productToRemove.count;
    }

    if (productToRemove.category === "Services & Games") {
      state.services.find(
        product => action.productId === product.productId
      ).quantity += productToRemove.count;

      state.services.find(
        product => action.productId === product.productId
      ).count -= productToRemove.count;
    }
    /** 
     * This is to make sure the customer is ordering the same item maximum for not more that two times
    if (state.orders.length !== 0) {
      state.orders.find(
        order => order.productId === action.productId
      ).quantity -= productToRemove.count;
    } **/

    return {
      ...state
    };
  }
  if (action.type === RESET_CART) {
    state = initState;
    return {
      ...state
    };
  } else {
    return {
      ...state
    };
  }
};
// refactor code

export default reducer;
