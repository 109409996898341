import React, { Component } from "react";
import { connect } from "react-redux";
import { checkCookie } from "../../Utils/Utils";
import Container from "react-bootstrap/Container";
import Header from "../../Content/Header/Header";
import TokenDetails from "../../Content/TokenDetails/TokenDetails";
require("./Tokens.scss");

class BuyTickets extends Component {
  componentDidMount() {
    if (checkCookie("emailId")) {
      this.setState({ cookie: true });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      cookie: false,
      pageName: "Buy Tickets"
    };
  }

  render() {
    return (
      <Container className="categories-page-container">
        <Header />
        <TokenDetails />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    //food: state ? state.food : []
    //orders: state ? state.orders : []
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(BuyTickets);
