import React, { Component } from "react";
import Header from "../../Content/Header/Header";
import { Redirect } from "react-router-dom";
import { checkCookie } from "../../Utils/Utils";
import { Card } from "react-bootstrap";
require("./booth.scss");

class KidzKorner extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="booth-container">
        <Header />
        <div className="booth-title">Kidz Korner</div>
        <Card.Img className="booth-image" src="/tie-die.jpg" />
        <Card.Text className="booth-description">
          Experience the excitement of our lucky dip game, where every prize is
          a winner! Dive into a world of color with our collection of tie-dye,
          printed and crewneck shirts, perfect for adding a touch of personality
          to your wardrobe.
        </Card.Text>
      </div>
    );
  }
}

export default KidzKorner;
