import React, { Component } from "react";
import RegistrationContent from "../../Content/RegistrationContent/RegistrationContent";
import RegistrationForm from "../../Content/RegistrationForm/RegistrationForm";

require("./RegistrationPage.scss");

class RegistrationPage extends Component {
  state = {
    userDetails: null
  };

  static getDerivedStateFromProps(props, state) {
    return {
      userDetails:
        props.location.state === undefined
          ? null
          : props.location.state.userDetails
    };
  }
  render() {
    return (
      <div className="registration-container">
        <RegistrationContent />
        <RegistrationForm data={this.state.userDetails} />
      </div>
    );
  }
}

export default RegistrationPage;
