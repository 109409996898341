import React, { Component } from "react";
import axios from "axios";
import { checkCookie, getCookie } from "../../Utils/Utils";
import {
  USER_EMAIL_API,
  TOKEN_API,
  TOKEN_HISTORY_API
} from "../../../constants/Constants";
import Table from "react-bootstrap/Table";
require("./TokenDetails.scss");

class TokenDetails extends Component {
  componentDidMount() {
    if (checkCookie("emailId")) {
      let email = getCookie("emailId");
      this.getUserDetails(email);
      this.setState({ emailAddress: email });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      user: "",
      tokenHistory: "",
      totalTokens: 0,
      paidTokens: 0,
      unPaidTokens: 0
    };

    this.handleToken = this.handleToken.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleToken(e) {
    let tokenDetails = this.state.tokenDetails;
    tokenDetails.tokens = e.target.value;
    this.setState(tokenDetails);
  }

  handleSubmit(e) {
    let tokenDetails = this.state.tokenDetails;
    tokenDetails.userId = this.state.user.userId;
    if (tokenDetails.tokens > 0) {
      axios
        .post(TOKEN_API, tokenDetails, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          this.setState({
            tokenDetails: {
              userId: "",
              type: "TOKEN_ORDER",
              tokens: 0,
              paid: false
            }
          });
          this.getUserDetails(this.state.user.emailAddress);
        });
    }
  }

  getUserDetails(email) {
    axios.get(USER_EMAIL_API + email).then(response => {
      this.setState({ user: response.data.userDetails });

      axios
        .get(TOKEN_HISTORY_API + response.data.userDetails.userId)
        .then(response => {
          this.setState({ tokenHistory: response.data.tokenHistory });
          this.setState({ totalTokens: response.data.totalTokens });
          this.setState({ paidTokens: response.data.paidTokens });
          this.setState({ unPaidTokens: response.data.unPaidTokens });
        });
    });
  }

  render() {
    const tokenHistory = this.state.tokenHistory;
    const totalTokens = this.state.totalTokens;
    const paidTokens = this.state.paidTokens;
    const unPaidTokens = this.state.unPaidTokens;
    return (
      <div className="token-history-container">
        <div className="token-history-title">ORDER HISTORY</div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Booth Name </th>
              <th>Type</th>
              <th>Tokens</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(tokenHistory).map(key => (
              <tr key={key}>
                <td>{parseInt(key) + 1}</td>
                <td>{tokenHistory[key].boothName}</td>
                <td>{tokenHistory[key].type}</td>
                <td>{tokenHistory[key].tokens}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div>Total Tokens purchased: {totalTokens}</div>
        <div>Tokens Paid: {paidTokens}</div>
        <div>Tokens that are not paid: {unPaidTokens}</div>
      </div>
    );
  }
}

export default TokenDetails;
