import React, { Component } from "react";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber
} from "../../Utils/Utils";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { HOSTURL } from "../../../constants/Constants";
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import { Card } from "react-bootstrap";

require("./RegistrationForm.scss");
class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstName: "",
        lastName: "",
        contactNumber: "",
        emailAddress: ""
      },
      errormessage: {
        firstName:
          "First Name cannot be empty and cannot be numbers or special characters",
        lastName:
          "First Name cannot be empty and cannot be numbers or special characters",
        contactNumber:
          "Contact Number Should be 10 digits without special Characters",
        emailAddress: "Enter a valid Email address"
      },
      firstNameValid: null,
      lastNameValid: null,
      contactNumberValid: null,
      emailAddressValid: null,
      redirect: false,
      showEmailModal: false,
      emailVerifyCode: 0
    };

    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleContactNumber = this.handleContactNumber.bind(this);
    this.handleEmailAddress = this.handleEmailAddress.bind(this);
    this.handleVerifyEmail = this.handleVerifyEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    let value = this.state;
    if (
      value.firstNameValid &&
      value.lastNameValid &&
      value.contactNumberValid &&
      value.emailAddressValid
    ) {
      let user = value.user;
      // email validation
      axios
        .get(HOSTURL + "/user/verify/email/" + user.emailAddress)
        .then(response => {
          this.setState({ emailVerifyCode: response.data });
          this.setState({ showEmailModal: true });
        });
    }
  }

  handleVerifyEmail(e) {
    if (Number(e.target.value) === this.state.emailVerifyCode) {
      this.setState({ showEmailModal: false });
      let user = this.state.user;
      axios
        .post(HOSTURL + "/user", user, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(
          response => {
            if (response.status === 201) {
              Cookies.set("emailId", response.data.emailAddress, {
                expires: 8
              });
              Cookies.set("userId", response.data.userId, {
                expires: 8
              });
              this.setState({ redirect: true });
            }
          },
          error => {
            console.log(error);
          }
        );
    }
  }

  handleFirstName(e) {
    let user = this.state.user;
    user.firstName = e.target.value;
    this.setState({ user });
    if (isValidName(user.firstName)) {
      this.setState({ firstNameValid: true });
    } else {
      this.setState({ firstNameValid: false });
    }
  }

  handleLastName(e) {
    let user = this.state.user;
    user.lastName = e.target.value;
    this.setState({ user });
    if (isValidName(user.lastName)) {
      this.setState({ lastNameValid: true });
    } else {
      this.setState({ lastNameValid: false });
    }
  }

  handleEmailAddress(e) {
    let user = this.state.user;
    user.emailAddress = e.target.value;
    this.setState({ user });
    if (isValidEmail(user.emailAddress)) {
      this.setState({ emailAddressValid: true });
    } else {
      this.setState({ emailAddressValid: false });
    }
  }

  handleContactNumber(e) {
    let user = this.state.user;
    user.contactNumber = e.target.value;
    this.setState({ user });
    if (isValidPhoneNumber(user.contactNumber)) {
      this.setState({ contactNumberValid: true });
    } else {
      this.setState({ contactNumberValid: false });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }
    return (
      <div className="registration-form-container">
        <Modal className="email-verification" show={this.state.showEmailModal}>
          <Modal.Header>
            <Modal.Title>Verify your Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="verification-modal-text">
              Enter the verification code sent to your email Address
            </div>
            <Form.Control
              className="verification-code"
              type="text"
              onChange={this.handleVerifyEmail}
            />
            <div className="verification-modal-text">
              Note: if you entered your email address and the verification code
              is not in your inbox, please check your junk/ spam folder{" "}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" style={{ borderRadius: "0%" }}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Card.Title className="registration-title">
          COMPLETE YOUR PROFILE{" "}
        </Card.Title>
        <Form className="register-form">
          <Form.Group className="form-field">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your First Name"
              name="firstName"
              value={this.state.user.firstName}
              onChange={this.handleFirstName}
              isInvalid={
                !this.state.firstNameValid && this.state.firstNameValid !== null
              }
              isValid={this.state.firstNameValid}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="form-field">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your Last Name"
              name="lastName"
              value={this.state.user.lastName}
              onChange={this.handleLastName}
              isInvalid={
                !this.state.lastNameValid && this.state.lastNameValid !== null
              }
              isValid={this.state.lastNameValid}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="form-field">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your Contact Number"
              name="phone"
              onChange={this.handleContactNumber}
              value={this.state.user.contactNumber}
              isInvalid={
                !this.state.contactNumberValid &&
                this.state.contactNumberValid !== null
              }
              isValid={this.state.contactNumberValid}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.contactNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="form-field">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              name="email"
              value={this.state.user.emailAddress}
              onChange={this.handleEmailAddress}
              isInvalid={
                !this.state.emailAddressValid &&
                this.state.emailAddressValid !== null
              }
              isValid={this.state.emailAddressValid}
              readOnly={this.state.emailAddress ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.errormessage.emailAddress}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <Button
          className="registration-confirm-button"
          variant="primary"
          onClick={this.handleSubmit}
          block
        >
          Confirm
        </Button>
      </div>
    );
  }
}

export default RegistrationForm;
