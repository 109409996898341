import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Container, Card, Button, Table, Alert } from "react-bootstrap";
import axios from "axios";

import { checkCookie, getCookie } from "../../Utils/Utils";
import { AUCTION_BID_API } from "../../../constants/Constants";
import Header from "../../Content/Header/Header";

require("./AuctionDetails.scss");
class AuctionDetails extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
    if (this.state.auctionItem !== undefined) {
      axios
        .get(AUCTION_BID_API + "/" + this.state.auctionItem.auctionId)
        .then(response => {
          this.setState({ bidDetails: response.data });
        });
    }
  }

  state = {
    auctionItem: null,
    bidDetails: []
  };

  static getDerivedStateFromProps(props, state) {
    return {
      auctionItem: props.location.item
    };
  }

  handleBid(e) {
    let auctionBid = {};
    auctionBid.bidValue = e.target.value;
    auctionBid.userId = getCookie("userId");
    auctionBid.auctionId = this.state.auctionItem.auctionId;

    axios
      .post(AUCTION_BID_API, auctionBid, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(
        response => {
          axios
            .get(AUCTION_BID_API + "/" + auctionBid.auctionId)
            .then(response => {
              this.setState({ bidDetails: response.data });
            });
        },
        error => {
          console.log(error);
        }
      );
  }

  handleRefresh(e) {
    axios
      .get(AUCTION_BID_API + "/" + this.state.auctionItem.auctionId)
      .then(response => {
        this.setState({ bidDetails: response.data });
      });
  }
  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };

    this.handleBid = this.handleBid.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    if (this.state.auctionItem === undefined) {
      return <Redirect to="/home" />;
    }
    const item = this.state.auctionItem;
    const bidDetails = this.state.bidDetails;
    return (
      <Container className="auctions-details-page-container">
        <Header />
        <Container className="back-button-container">
          <Link
            to={{
              pathname: "/home"
            }}
          >
            <Button className="button back-button">
              Back to Auctions Page
            </Button>
          </Link>
          <Alert className="starting-bid" variant="primary">
            STARTING BID: {item.startingValue} TOKENS
          </Alert>
        </Container>
        <Container className="auction-item-container">
          <Card className="auction-item-card">
            <Card.Img className="auction-image" src={item.imageName} />
            <Card.Body>
              <Card.Title
                className="auction-title"
                style={{ fontSize: "1.75rem" }}
              >
                {item.auctionName}
              </Card.Title>
              <Card.Text className="auction-description">
                {item.description}
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
        <Container className="auction-main-container">
          {item.active ? (
            <Container className="auction-button-container">
              <div className="auction-bid-title">INCREASE BID BY</div>

              <Button
                className="auction-button"
                onClick={this.handleBid}
                value="5"
                variant="primary"
              >
                5 tokens
              </Button>
              <Button
                className="auction-button"
                onClick={this.handleBid}
                value="10"
                variant="primary"
              >
                10 tokens
              </Button>
              <Button
                className="auction-button"
                onClick={this.handleBid}
                value="25"
                variant="primary"
              >
                25 tokens
              </Button>
              <Button
                className="auction-button"
                onClick={this.handleBid}
                value="50"
                variant="primary"
              >
                50 tokens
              </Button>
              <Button
                className="auction-button"
                onClick={this.handleBid}
                value="100"
                variant="primary"
              >
                100 tokens
              </Button>
            </Container>
          ) : (
            <Container className="auction-button-container">
              <div className="auction-bid-title">The Auction is closed!</div>
            </Container>
          )}

          <Container className="auction-value-container">
            <div className="auction-refresh">
              <Button
                className="auction-refresh-button"
                variant="primary"
                onClick={this.handleRefresh}
              >
                REFRESH
              </Button>
            </div>

            <Table className="auction-details" striped bordered hover>
              <thead>
                <tr>
                  <th style={{ width: "70%", textAlign: "left" }}>Name</th>
                  <th style={{ width: "20%" }}>Tokens Bid</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(bidDetails || []).map(key => (
                  <tr key={key}>
                    <td style={{ width: "70%", textAlign: "left" }}>
                      {bidDetails[key].name}
                    </td>
                    <td>{bidDetails[key].bidValue}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </Container>
      </Container>
    );
  }
}

export default AuctionDetails;
