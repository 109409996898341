import React, { Component } from "react";
import { Container, DropdownButton, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { RESONATE_PREVIEWS } from "../../../constants/Constants";

require("./PreviewCategories.scss");

class PreviewCategories extends Component {
  render() {
    const categoryName = this.props.categoryName;
    return (
      <Container className="categories-container">
        <div className="categories-navigation">
          {Object.keys(RESONATE_PREVIEWS).map(key => (
            <Link key={key} className="nav-anchor" to={key}>
              {RESONATE_PREVIEWS[key]}
            </Link>
          ))}
        </div>
        <DropdownButton
          className="categories-dropdown"
          title={categoryName ? categoryName : "Select Category"}
        >
          {Object.keys(RESONATE_PREVIEWS).map(key => (
            <Dropdown.Item as={Link} to={key} key={key}>
              {RESONATE_PREVIEWS[key]}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </Container>
    );
  }
}

export default PreviewCategories;
