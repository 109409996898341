import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

require("./Promos.scss");
class Promos extends Component {
  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    return (
      <Carousel
        className="promo-container"
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1800}
      >
        <Card className="promo-card">
          <Card.Img
            className="promo-image"
            src="/mutton-biryani.png"
            alt="mutton biryani"
          />
          <Card.ImgOverlay>
            <Card.Title className="promo-title">Biriyanis</Card.Title>
          </Card.ImgOverlay>
        </Card>
        <Card className="promo-card">
          <Card.Img
            className="promo-image"
            src="/indian-ethinic-wear.png"
            alt="Indian Ethnic wear"
          />
          <Card.ImgOverlay>
            <Card.Title className="promo-title">Indian Ethnic Wear</Card.Title>
          </Card.ImgOverlay>
        </Card>
        <Card className="promo-card">
          <Card.Img
            className="promo-image"
            src="/fun-auctions.png"
            alt="fun auctions"
          />
          <Card.ImgOverlay>
            <Card.Title className="promo-title">Fun Auctions</Card.Title>
          </Card.ImgOverlay>
        </Card>
        <Card className="promo-card">
          <Card.Img
            className="promo-image"
            src="/indian-food-2.png"
            alt="Indian foods"
          />
          <Card.ImgOverlay>
            <Card.Title className="promo-title">Tasty Indian Foods</Card.Title>
          </Card.ImgOverlay>
        </Card>
        <Card className="promo-card">
          <Card.Img
            className="promo-image"
            src="/fun-activity.png"
            alt="Indian foods"
          />
          <Card.ImgOverlay>
            <Card.Title className="promo-title">Fun Activities</Card.Title>
          </Card.ImgOverlay>
        </Card>
        <Card className="promo-card">
          <Card.Img
            className="promo-image"
            src="/hand-crafts.png"
            alt="Indian foods"
          />
          <Card.ImgOverlay>
            <Card.Title className="promo-title">Arts & Crafts</Card.Title>
          </Card.ImgOverlay>
        </Card>
        <Card className="promo-card">
          <Card.Img
            className="promo-image"
            src="/indian-snacks.png"
            alt="Indian foods"
          />
          <Card.ImgOverlay>
            <Card.Title className="promo-title">Indian Snacks</Card.Title>
          </Card.ImgOverlay>
        </Card>
      </Carousel>
    );
  }
}

export default Promos;
