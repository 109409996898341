import React, { Component } from "react";
require("./RegistrationContent.scss");
class RegistrationContent extends Component {
  render() {
    return (
      <div className="registration-content-container">
        <img
          className="registration-logo"
          src="/new-beginnings-christian-fellowship-logo.png"
          alt="NBCF Logo"
        />
        <div className="registration-text">
          Started a decade ago by a small group of families in Southern
          California, New Beginnings Christian Fellowship is a volunteer-led
          organization that exists to serve indigenous missions in India. We
          invite you to pray, serve and give sacrificially with us.
        </div>
      </div>
    );
  }
}

export default RegistrationContent;
