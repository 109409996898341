import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { checkCookie } from "../../Utils/Utils";
import Header from "../../Content/Header/Header";
import ProfileDetails from "../../Content/ProfileDetails/ProfileDetails";

class ProfilePage extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="profile-page-container">
        <Header />
        <ProfileDetails />
      </div>
    );
  }
}

export default ProfilePage;
