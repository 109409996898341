import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { Button, Form, Modal } from "react-bootstrap";

import {
  USER_EMAIL_API,
  MIN_PRODUCTS_API
} from "../../../../constants/Constants";
import { isValidEmail } from "../../../Utils/Utils";
import { userDetails, setProductMinimumValue } from "../../../../redux/action";

require("./Email.scss");
class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      pathname: "",
      userDetails: "",
      code: "",
      emailAddress: "",
      disabled: true
    };
    this.handleData = this.handleData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleVerifyCode = this.handleVerifyCode.bind(this);
    this.showModal = false;
  }

  handleClose(e) {
    let userDetails = this.state.userDetails;
    if (userDetails.uniqueId === Number(this.state.code)) {
      if (userDetails.active) {
        Cookies.set("emailId", userDetails.emailAddress, {
          expires: 8
        });
        Cookies.set("userId", userDetails.userId, {
          expires: 8
        });
        this.setState({ pathname: "/home" });
      } else {
        this.setState({ pathname: "/registration" });
      }
      this.setState({ showModal: false });
    }
  }

  handleVerifyCode(e) {
    this.setState({ code: e.target.value });
    let userDetails = this.state.userDetails;
    if (userDetails.uniqueId === Number(e.target.value)) {
      if (userDetails.active) {
        Cookies.set("emailId", userDetails.emailAddress, {
          expires: 8
        });
        Cookies.set("userId", userDetails.userId, {
          expires: 8
        });
        this.props.userDetails(userDetails);
        axios.get(MIN_PRODUCTS_API).then(response => {
          this.props.setProductMinimumValue(response.data);
        });
        this.setState({ pathname: "/home" });
      } else {
        this.setState({ pathname: "/registration" });
      }
    }
  }

  handleSubmit(e) {
    let data = this.state.data;
    let url = "";

    if (isValidEmail(data)) {
      url = USER_EMAIL_API + data + "?verification=true";
      axios.get(url).then(response => {
        if (response.data.userDetails === undefined) {
          this.setState({ redirect: "/registration" });
        } else {
          this.setState({ showModal: true });
          this.setState({ userDetails: response.data.userDetails });
          this.setState({
            emailAddress: response.data.userDetails.emailAddress
          });
        }
      });
    }
  }

  handleData(e) {
    this.setState({ data: e.target.value });
  }

  isValidEmail(value) {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(value);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div className="email-container">
        <Modal className="email-verification" show={this.state.showModal}>
          <Modal.Header>
            <Modal.Title>Verify your details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="verification-modal-text">
              Enter the verification code sent to your registered email address
            </div>
            <Form.Control
              className="verification-code"
              type="text"
              onChange={this.handleVerifyCode}
            />
          </Modal.Body>
          <Modal.Footer>
            <Link
              to={{
                pathname: this.state.pathname,
                state: this.state
              }}
            >
              <Button variant="primary" style={{ borderRadius: "0%" }}>
                Confirm
              </Button>
            </Link>
          </Modal.Footer>
        </Modal>
        <div className="email-group">
          <div className="email-heading">
            Let's check whether we have your information.
          </div>
          <div className="email-fields">
            <Form.Control
              className="email"
              type="text"
              onChange={this.handleData}
              placeholder="Enter your Email Address"
            />
            <Button className="button" onClick={this.handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    userDetails: user => {
      dispatch(userDetails(user));
    },
    setProductMinimumValue: minValue => {
      dispatch(setProductMinimumValue(minValue));
    }
  };
};

export default connect(null, mapDispatchToProps, null, {
  pure: false
})(Email);
