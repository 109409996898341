import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Card, Alert } from "react-bootstrap";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import IconButton from "@mui/material/IconButton";

import { getCookie, checkCookie } from "../../Utils/Utils";
import { foodList, addFoodToCart, setOrderList } from "../../../redux/action";
import { ORDER_API, PRODUCTS_API } from "../../../constants/Constants";
import Header from "../../Content/Header/Header";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";

import axios from "axios";
import PreviewCategories from "../../Content/PreviewCategories/PreviewCategories";
import Banner from "../../Content/Banner/Banner";
require("./Food.scss");

class Food extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    } else {
      this.getUserDetails(getCookie("emailId"));
      if (this.props.food.length === 0) {
        axios
          .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
          .then(response => {
            this.handleFoodList(response.data.products);
          });
      }
    }
  }

  handleFoodList(products) {
    this.props.foodList(products);
  }

  handleAddToCart(productId) {
    this.props.addFoodToCart(productId);
  }

  getUserDetails(email) {
    if (this.props.orders === undefined) {
      axios.get(ORDER_API + "?userId=" + getCookie("userId")).then(response => {
        this.props.setOrderList(response.data.orderList);
      });
    }
  }

  disableButton(productId, maxItems) {
    let disable = false;
    if (this.props.orders !== undefined) {
      let product = this.props.orders.find(
        order => order.productId === productId
      );
      if (product !== undefined) {
        if (product.quantity >= 2 * Number(maxItems)) {
          disable = true;
        }
      }
    }
    return disable;
  }

  constructor(props) {
    super(props);
    this.state = {
      cookie: false,
      pageName: "Food",
      food: [],
      minValue: this.props.minValue,
      orders: ""
    };

    this.handleLike = this.handleLike.bind(this);
    this.handleDisLike = this.handleDisLike.bind(this);
  }

  handleLike(productId) {
    axios.put(PRODUCTS_API + "/like/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleFoodList(response.data.products);
        });
    });
  }

  handleDisLike(productId) {
    axios.put(PRODUCTS_API + "/dislike/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleFoodList(response.data.products);
        });
    });
  }

  render() {
    const food = this.props.food;
    return (
      <Container className="categories-page-container">
        <Header />
        <Banner />
        <PreviewCategories categoryName={this.state.pageName} />
        <Alert className="alert-message" key="primary" variant="primary">
          The items listed below are available in person on May 5<sup>th</sup>{" "}
          at Resonate.
        </Alert>
        <Container className="food-container">
          {Object.keys(food).map(key => (
            <Card className="food-card" key={key}>
              <Card.Img className="food-image" src={food[key].imageName} />
              <Card.Body className="food-card-body">
                <Accordion>
                  <AccordionSummary className="food-title">
                    {food[key].productName} <ExpandMoreIcon />
                  </AccordionSummary>
                  <AccordionDetails className="food-description">
                    {food[key].description}
                  </AccordionDetails>
                </Accordion>
                {/**
                <Card.Text>
                  <span className="food-price">{food[key].value} </span>
                  <span>tokens</span>
                  <span className="food-quantity">{food[key].quantity} </span>
                  <span> item(s) left</span>
                </Card.Text>
                <Card.Text
                  className="food-alert"
                  hidden={!(food[key].count >= food[key].maxItems)}
                >
                  You can only add {food[key].maxItems} items of the same type
                </Card.Text>
                <Card.Text
                  className="food-alert"
                  hidden={
                    !this.disableButton(food[key].productId, food[key].maxItems)
                  }
                >
                  You reached the maximum limit for this item.
                </Card.Text>
                <Button
                  className="food-button"
                  disabled={
                    food[key].quantity === 0 ||
                    food[key].count >= food[key].maxItems ||
                    this.disableButton(food[key].productId, food[key].maxItems)
                  }
                  onClick={() => {
                    this.handleAddToCart(food[key].productId);
                  }}
                >
                  Add To Cart
                </Button>
                 */}
                <Card.Text className="food-feedback">
                  <IconButton
                    onClick={() => {
                      this.handleLike(food[key].productId);
                    }}
                  >
                    <ThumbUpOutlinedIcon />
                  </IconButton>{" "}
                  {food[key].likes}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    food: state ? state.food : [],
    minValue: state ? state.minValue : 0,
    user: state ? state.user : null,
    orders: state ? state.orders : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    foodList: food => {
      dispatch(foodList(food));
    },
    addFoodToCart: productId => {
      dispatch(addFoodToCart(productId));
    },
    setOrderList: orders => {
      dispatch(setOrderList(orders));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(Food);
