import React, { Component } from "react";
import { Alert, Card, Container } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";

import { craftList, addCraftsToCart } from "../../../redux/action";
import { checkCookie } from "../../Utils/Utils";
import { PRODUCTS_API } from "../../../constants/Constants";

import Header from "../../Content/Header/Header";
import PreviewCategories from "../../Content/PreviewCategories/PreviewCategories";

require("./Crafts.scss");

class Crafts extends Component {
  componentDidMount() {
    if (checkCookie("emailId")) {
      this.setState({ cookie: true });
    }

    if (this.props.crafts.length === 0) {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleCraftsList(response.data.products);
        });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      cookie: false,
      pageName: "Crafts & More"
    };
  }

  handleLike(productId) {
    axios.put(PRODUCTS_API + "/like/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleCraftsList(response.data.products);
        });
    });
  }

  handleDisLike(productId) {
    axios.put(PRODUCTS_API + "/dislike/" + productId).then(response => {
      axios
        .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
        .then(response => {
          this.handleCraftsList(response.data.products);
        });
    });
  }

  handleCraftsList(products) {
    this.props.craftList(products);
  }

  handleAddToCart(productId) {
    this.props.addCraftsToCart(productId);
  }

  render() {
    const crafts = this.props.crafts;
    return (
      <Container className="categories-page-container">
        <Header />
        <PreviewCategories categoryName={this.state.pageName} />
        <Alert className="alert-message" key="primary" variant="primary">
          The items listed below are available in person on May 5<sup>th</sup>{" "}
          at Resonate.
        </Alert>
        <Container className="craft-container">
          {Object.keys(crafts).map(key => (
            <Card className="craft-card" key={key}>
              <Card.Img className="craft-image" src={crafts[key].imageName} />
              <Card.Body className="craft-card-body">
                <Accordion>
                  <AccordionSummary className="craft-title">
                    {crafts[key].productName} <ExpandMoreIcon />
                  </AccordionSummary>
                  <AccordionDetails className="craft-description">
                    {crafts[key].description}
                  </AccordionDetails>
                </Accordion>
                <Card.Text className="craft-feedback">
                  <IconButton
                    onClick={() => {
                      this.handleLike(crafts[key].productId);
                    }}
                  >
                    <ThumbUpOutlinedIcon />
                  </IconButton>{" "}
                  {crafts[key].likes} {"    "}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    crafts: state ? state.crafts : []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    craftList: crafts => {
      dispatch(craftList(crafts));
    },
    addCraftsToCart: productId => {
      dispatch(addCraftsToCart(productId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(Crafts);
