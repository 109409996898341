import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav, NavDropdown, Offcanvas } from "react-bootstrap";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { RESONATE_PREVIEWS } from "../../../constants/Constants";
import { USER_EMAIL_API } from "../../../constants/Constants";
import { getCookie } from "../../Utils/Utils";
import axios from "axios";

require("./Header.scss");

class Header extends Component {
  componentDidMount() {
    this.getQRCode(getCookie("emailId"));
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      userId: "",
      qrCode: ""
    };
  }

  getQRCode(email) {
    axios.get(USER_EMAIL_API + email).then(response => {
      this.setState({
        userId: response.data.userDetails.userId,
        qrCode: response.data.userDetails.qrCode
      });
    });
  }
  handleShow() {
    this.setState({ show: this.state.show ? false : true });
  }
  render() {
    return (
      <Navbar sticky="top" variant="dark">
        <Container className="navigation-container">
          <Navbar.Brand className="navigation-brand" as={Link} to="/home">
            <img className="navigation-logo" src="/NBCF-LOGO.png" alt="" />
            <div className="navigation-logo-name">
              New Beginnings Christian Fellowship
            </div>
          </Navbar.Brand>
          <Nav className="navigation-desktop">
            <Nav.Link
              className="navigation-link"
              href="https://secure.ncfgiving.com/GXDonateNow?id=a0Ui0000007zz5OEAQ"
              target="_blank"
            >
              <div className="navigation-title">Donate</div>
            </Nav.Link>
            <Nav.Link as={Link} className="navigation-link" to="/faq">
              <div className="navigation-title">FAQ'S</div>
            </Nav.Link>
            <Nav.Link as={Link} className="navigation-link" to="/home">
              <div className="navigation-title">Silent Auction</div>
            </Nav.Link>
            <Nav.Link as={Link} className="navigation-link" to="/services">
              <div className="navigation-title">Shop Online</div>
            </Nav.Link>
            <NavDropdown
              title="Resonate Exclusive"
              className="navigation-dropdown"
            >
              {Object.keys(RESONATE_PREVIEWS).map(key => (
                <NavDropdown.Item
                  as={Link}
                  key={key}
                  className="navigation-link"
                  to={key}
                >
                  {RESONATE_PREVIEWS[key]}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Link as={Link} className="navigation-link" to="/map">
              <div className="navigation-title">Event Map</div>
            </Nav.Link>
            <Nav.Link as={Link} className="navigation-link" to="/tokens">
              <div className="navigation-title">Order History</div>
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="navigation-link-desktop-icon"
              to="/cart"
            >
              <ShoppingCartIcon className="navigation-icon" />
              <div className="navigation-title">Cart</div>
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="navigation-link-desktop-icon"
              to="/profile"
            >
              <AccountCircleIcon className="navigation-icon" />
              <div className="navigation-title">Account</div>
            </Nav.Link>
          </Nav>
          <Nav.Link
            as={Link}
            className="navigation-link-mobile-icon"
            to="/cart"
          >
            <ShoppingCartIcon className="navigation-icon" />
          </Nav.Link>
          <Nav.Link as={Link} className="navigation-link-mobile-icon" to="#">
            <MenuIcon
              className="navigation-icon"
              onClick={() => {
                this.handleShow();
              }}
            />
          </Nav.Link>
          <Offcanvas
            show={this.state.show}
            onHide={() => {
              this.handleShow();
            }}
            placement="end"
            className="navigation-mobile-canvas"
          >
            <Offcanvas.Header closeButton>
              <Navbar.Brand className="navigation-brand" as={Link} to="/home">
                <img className="navigation-logo" src="/NBCF-LOGO.png" alt="" />
                <div className="navigation-logo-name">
                  New Beginnings Christian Fellowship
                </div>
              </Navbar.Brand>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="navigation-mobile">
                <Navbar.Text>User Id: {this.state.userId}</Navbar.Text>
                <img
                  src={this.state.qrCode}
                  alt="user-qr"
                  className="profile-QR"
                />
                <Nav.Link
                  className="navigation-link"
                  href="https://mygiving.secure.force.com/GXDonateNow?id=a0Ui0000007zz5OEAQ"
                  target="_blank"
                >
                  <div className="navigation-title">Donate</div>
                </Nav.Link>
                <Nav.Link as={Link} className="navigation-link" to="/faq">
                  <div className="navigation-title">FAQ'S</div>
                </Nav.Link>
                <Nav.Link as={Link} className="navigation-link" to="/home">
                  <div className="navigation-title">Silent Auction</div>
                </Nav.Link>
                <Nav.Link as={Link} className="navigation-link" to="/services">
                  <div className="navigation-title">Shop Online</div>
                </Nav.Link>
                <Navbar.Text>Resonate Exclusives</Navbar.Text>
                <Nav className="navigation-canvas">
                  {Object.keys(RESONATE_PREVIEWS).map(key => (
                    <Nav.Link
                      as={Link}
                      key={key}
                      className="navigation-link"
                      to={key}
                    >
                      {RESONATE_PREVIEWS[key]}
                    </Nav.Link>
                  ))}
                </Nav>
                <Nav.Link as={Link} className="navigation-link" to="/map">
                  <div className="navigation-title">Event Map</div>
                </Nav.Link>
                <Nav.Link as={Link} className="navigation-link" to="/tokens">
                  <div className="navigation-title">Order History</div>
                </Nav.Link>
                <Nav.Link as={Link} className="navigation-link" to="/profile">
                  <div className="navigation-title">Account</div>
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
