import React, { Component } from "react";
import Email from "../Email/Email";
import AboutContent from "../AboutContent/AboutContent";
import Promos from "../Promos/Promos";

require("./GuestContent.scss");
class GuestContent extends Component {
  render() {
    return (
      <div className="guest-content-container">
        <AboutContent />
        <Email />
        <Promos />
      </div>
    );
  }
}

export default GuestContent;
