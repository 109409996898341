import React, { Component } from "react";
import Header from "../../Content/Header/Header";
import { Redirect } from "react-router-dom";
import { checkCookie } from "../../Utils/Utils";
import { Card } from "react-bootstrap";
import { TEA_TIME } from "../../../constants/Constants";
require("./booth.scss");

class TeaTime extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="booth-container">
        <Header />
        <div className="booth-title">Tea Time</div>
        <Card.Img className="booth-image" src="/tea-time.jpg" />
        <Card.Title className="booth-text">ITEMS AVAILABLE</Card.Title>
        {TEA_TIME.map(items => (
          <Card.Text className="booth-item">{items}</Card.Text>
        ))}
      </div>
    );
  }
}

export default TeaTime;
