export const HOSTURL = "https://api.nbcfonline.com";
//export const HOSTURL = "http://localhost:8080";
export const PRODUCTS_API = HOSTURL + "/products";
export const MIN_PRODUCTS_API = HOSTURL + "/min/products";
export const ORDER_API = HOSTURL + "/order";
export const AUCTION_API = HOSTURL + "/auctions";
export const AUCTION_BID_API = AUCTION_API + "/bid";
export const AUCTION_FAVORITE_API = AUCTION_API + "/favorites";
export const USER_API = HOSTURL + "/user";
export const USER_EMAIL_API = USER_API + "/email/";
//export const USER_CONTACT_API = USER_API + "/contact/";
export const TOKEN_API = HOSTURL + "/tokens";
export const TOKEN_HISTORY_API = HOSTURL + "/tokenHistory/";

export const CATEGORIES_UPDATED = {
  "/services": "Services & Games"
};

export const RESONATE_PREVIEWS = {
  "/food": "Food",
  "/snacks": "Snacks & More"
};

export const CATEGORIES = [
  "Food",
  "Snacks",
  "Ethnic Wear & Accessories",
  "Crafts & More",
  "Services & Games"
];

export const BIRIYANI_BAZAAR = [
  "South Indian Mutton Biriyani",
  "Fish Biriyani",
  "Veal Biriyani",
  "Chicken Biriyani",
  "Boti",
  "Crab",
  "Thala Curry"
];

export const CHAAT_CORNER = ["Bhel Puri"];

export const COFFEE_CORNER = [
  "BBQ Ribs",
  "Bonda",
  "Chicken 65",
  "Cutlet - Beef",
  "Cutlet - Tuna",
  "Cutlet - Turkey",
  "Filter Coffee",
  "Ginger Tea",
  "Jamaican Spicy Beef Patty",
  "Kabob - Beef",
  "Puffs - Chicken",
  "Queso with chips",
  "Roll - Beef",
  "Roll - Chicken",
  "Vadai - Masala",
  "Vadai - Medhu"
];

export const PAROTTA_KADAI = ["Parotta with Chicken Salna"];

export const DELIGHTFUL_DRINKS = ["Mango Lassi", "Rose Milk"];

export const COOKIE_COUTURE = [
  "Cake Pops",
  "Caramel Flan",
  "Cookie Mix Jars",
  "Mango Custard",
  "Mango Mousse",
  "Tres Leches Cake"
];

export const TEA_TIME = ["Milk Tea w/ Boba", "Thai Tea w/ Boba", "Fries"];

export const TIFFIN_TABLE = [
  "Kerala Appam with Fish Curry",
  "Kerala Appam with Mutton Curry",
  "Kerala Appam with Chicken Curry",
  "Kerala Appam with Veg Kurma",
  "Kerala Appam with Coconut Milk",
  "Idly with Fish Curry",
  "Idly with Mutton Curry",
  "Idly with Chicken Curry",
  "Idly with Veg Kurma",
  "Idly with Sambar and Chutney",
  "Idyappam with Fish Curry",
  "Idyappam with Mutton Curry",
  "Idyappam with Chicken Curry",
  "Idyappam with Veg Kurma",
  "Puttu with Fish Curry",
  "Puttu with Mutton Curry",
  "Puttu with Chicken Curry",
  "Puttu with Veg Kurma",
  "Puttu with Kadalai Curry"
];

export const SNACK_SHACK = [
  "Adhirasam",
  "Badam Halwa",
  "Badusha",
  "Banana Chips",
  "Bombay Duck",
  "Cashew Pagoda",
  "Coconut Burfi",
  "Curry Leaves Packet",
  "Diamond Cuts (Sweet)",
  "Ellu Podi",
  "Fruit Cake",
  "Idly Podi",
  "Kadalai Mittai",
  "Kadalai Urundai",
  "Kara Sev",
  "Karupati Sev",
  "Kodubale",
  "Laddu - Boondi",
  "Laddu - Ravva",
  "Macaroons",
  "Masala Kadalai",
  "Mixture",
  "Mittai",
  "Murukku",
  "Mysore Pak",
  "Nei Orundai",
  "Nethili Karuvadu Fry",
  "Onion Pakoda",
  "Paniyaram - Kara",
  "Paniyaram - Sweet",
  "Pavakkai Chips",
  "Pickle - Lemon",
  "Pickle - Garlic",
  "Pickle - Mango",
  "Pickle - King Fish",
  "Pickle - Shrimp",
  "Pickle - Beef",
  "Poli",
  "Puliyodharai Mix",
  "Ribbon Pakoda",
  "Rose Cookies",
  "Seedai",
  "Thattai"
];
