export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const RESET_CART = "RESET_CART";
export const USER_ORDERS = "USER_ORDERS";
// code refactoring
export const USER_DETAILS = "USER_DETAILS";
export const FOOD_LIST = "FOOD_LIST";
export const ADD_FOOD_TO_CART = "ADD_FOOD_TO_CART";
export const RAFFLE_LIST = "RAFFLE_LIST";
export const SNACKS_LIST = "SNACKS_LIST";
export const ADD_SNACKS_TO_CART = "ADD_SNACKS_TO_CART";
export const ETHNIC_LIST = "ETHNIC_LIST";
export const ADD_ETHNIC_TO_CART = "ADD_ETHNIC_TO_CART";
export const CRAFT_LIST = "CRAFT_LIST";
export const ADD_CRAFT_TO_CART = "ADD_CRAFT_TO_CART";
export const SERVICES_LIST = "SERVICES_LIST";
export const ADD_SERVICES_TO_CART = "ADD_SERVICES_TO_CART";
export const SET_PRODUCT_MINIMUM_VALUE = "SET_PRODUCT_MINIMUM_VALUE";
