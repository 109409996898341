import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { checkCookie } from "../../Utils/Utils";
import Header from "../../Content/Header/Header";

class InteractiveMap extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <div className="interactivemap-container">
        <Header />
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 750 1043"
        >
          <image
            width="750"
            height="1043"
            xlinkHref="/NBCF-Resonate-Interactive-Map-vertical.png"
          ></image>{" "}
          {/* eslint-disable-next-line */}
          <a xlinkHref="/cotton-candy">
            <rect
              x="14"
              y="18"
              fill="#fff"
              opacity="0"
              width="82"
              height="132"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/cookie-couture">
            <rect
              x="99"
              y="19"
              fill="#fff"
              opacity="0"
              width="113"
              height="127"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/tea-time">
            <rect
              x="14"
              y="155"
              fill="#fff"
              opacity="0"
              width="80"
              height="115"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/dazzling-designs">
            <rect
              x="14"
              y="272"
              fill="#fff"
              opacity="0"
              width="81"
              height="111"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/game-booth">
            <rect
              x="15"
              y="393"
              fill="#fff"
              opacity="0"
              width="78"
              height="243"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/ethnic-elegance">
            <rect
              x="91"
              y="929"
              fill="#fff"
              opacity="0"
              width="71"
              height="84"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/adorn-adore">
            <rect
              x="164"
              y="930"
              fill="#fff"
              opacity="0"
              width="76"
              height="84"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/kidz-korner">
            <rect
              x="244"
              y="931"
              fill="#fff"
              opacity="0"
              width="64"
              height="85"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/chaat-cart">
            <rect
              x="536"
              y="24"
              fill="#fff"
              opacity="0"
              width="72"
              height="83"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/delightful-drinks">
            <rect
              x="613"
              y="23"
              fill="#fff"
              opacity="0"
              width="122"
              height="84"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/snack-shack">
            <rect
              x="650"
              y="146"
              fill="#fff"
              opacity="0"
              width="79"
              height="183"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/biriyani-bazar">
            <rect
              x="650"
              y="331"
              fill="#fff"
              opacity="0"
              width="80"
              height="187"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/parotta-kadai">
            <rect
              x="650"
              y="522"
              fill="#fff"
              opacity="0"
              width="81"
              height="100"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/tiffin-table">
            <rect
              x="650"
              y="627"
              fill="#fff"
              opacity="0"
              width="82"
              height="206"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/coffee-corner">
            <rect
              x="607"
              y="888"
              fill="#fff"
              opacity="0"
              width="79"
              height="128"
            ></rect>
          </a>
          {/* eslint-disable-next-line */}
          <a xlinkHref="/home">
            <rect
              x="412"
              y="862"
              fill="#fff"
              opacity="0"
              width="124"
              height="81"
            ></rect>
          </a>
        </svg>
      </div>
    );
  }
}

export default InteractiveMap;
