import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import IconButton from "@mui/material/IconButton";

import { checkCookie, getCookie } from "../../Utils/Utils";
import {
  AUCTION_API,
  AUCTION_FAVORITE_API
} from "../../../constants/Constants";

import Header from "../../Content/Header/Header";
import TokenMessage from "../../Content/TokenMessage/TokenMessage";
import Banner from "../../Content/Banner/Banner";

require("./AuctionsPage.scss");

class AuctionsPage extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    }

    axios.get(AUCTION_API + "/" + getCookie("userId")).then(response => {
      this.setState({ auctions: response.data.auctions });
    });
  }

  handleFavorites(auction) {
    console.log(auction);
    let auctionFavoriteItem = {};
    auctionFavoriteItem.auctionId = auction.auctionId;
    auctionFavoriteItem.userId = getCookie("userId");

    axios
      .post(AUCTION_FAVORITE_API, auctionFavoriteItem, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        let auctions = this.state.auctions;
        let auctionItem = auctions.find(
          item => item.auctionId === auction.auctionId
        );
        if (auctionItem.favorite) {
          auctionItem.favorite = false;
        } else {
          auctionItem.favorite = true;
        }
        this.setState({ auctions });
      });
  }

  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      auctions: []
    };
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    const auctions = this.state.auctions;
    return (
      <Container className="auctions-page-container">
        <Header />
        <Banner />
        <TokenMessage />
        <Container className="auction-container">
          {Object.keys(auctions).map(key => (
            <Card className="auction-card" key={key}>
              <Card.Img
                className="auction-image"
                src={auctions[key].imageName}
              ></Card.Img>
              <Card.Body>
                <Card.Title className="auction-title">
                  {auctions[key].auctionName}
                </Card.Title>
                <Card.Text className="auction-favourite">
                  <IconButton
                    onClick={() => {
                      this.handleFavorites(auctions[key]);
                    }}
                  >
                    {auctions[key].favorite ? (
                      <FavoriteIcon className="icon-favorite" />
                    ) : (
                      <FavoriteBorderIcon />
                    )}
                  </IconButton>{" "}
                </Card.Text>
                <Row className="bidding-bar">
                  <Col>Starting Bid: {auctions[key].startingValue} </Col>
                  {auctions[key].currentBidValue === undefined ? (
                    ""
                  ) : (
                    <Col>{"Current Bid:" + auctions[key].currentBidValue}</Col>
                  )}
                  {auctions[key].userBidValue === 0 ||
                  auctions[key].userBidValue === undefined ? (
                    <Col>
                      {"Your Bid:"}
                      <br /> {"-"}
                    </Col>
                  ) : (
                    <Col
                      className="bid-details"
                      style={{
                        color:
                          auctions[key].currentBidValue >
                          auctions[key].userBidValue
                            ? "red"
                            : "green"
                      }}
                    >
                      {"Your Bid:" + auctions[key].userBidValue}
                    </Col>
                  )}
                </Row>
                <Link
                  to={{
                    pathname: "/auctiondetails",
                    item: auctions[key]
                  }}
                >
                  <Button className="auction-bid">Bid</Button>
                </Link>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Container>
    );
  }
}

export default AuctionsPage;
