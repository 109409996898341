import React, { Component } from "react";
import AuctionsPage from "../AuctionsPage/AuctionsPage";
import GuestHomePage from "../GuestHomePage/GuestHomePage";
import { checkCookie } from "../../Utils/Utils";

class HomePage extends Component {
  componentDidMount() {
    if (checkCookie("emailId")) {
      this.setState({ cookie: true });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      cookie: false
    };
  }

  render() {
    return this.state.cookie ? <AuctionsPage /> : <GuestHomePage />;
  }
}

export default HomePage;
