import React, { Component } from "react";
import { connect } from "react-redux";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { removeFromCart, resetCart } from "../../../redux/action";
import Button from "react-bootstrap/Button";
import { getCookie } from "../../Utils/Utils";
import axios from "axios";
import { ORDER_API, USER_EMAIL_API } from "../../../constants/Constants";

require("./OrderSubmit.scss");

class OrderSubmit extends Component {
  componentDidMount() {
    axios.get(USER_EMAIL_API + getCookie("emailId")).then(response => {
      this.setState({ userDetails: response.data.userDetails });
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      comments: "",
      cartInformation: "Cart is empty!!!",
      userDetails: "",
      active: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleComments = this.handleComments.bind(this);
    this.handleCartInformation = this.handleCartInformation.bind(this);
  }

  getUserDetails(email) {
    axios.get(USER_EMAIL_API + email).then(response => {
      this.setState({ userDetails: response.data.userDetails });
    });
  }

  handleSubmit(e) {
    this.setState({ active: true });
    let order = {};
    let orderList = [];
    for (let product in this.props.cartItems) {
      orderList.push({
        productId: this.props.cartItems[product].productId,
        quantity: this.props.cartItems[product].count,
        productValue: this.props.cartItems[product].totalValue
      });
    }
    order.userId = getCookie("userId");
    order.orderList = orderList;
    order.totalValue = this.props.totalValue;
    order.comments = this.state.comments;

    axios
      .post(ORDER_API, order, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(
        response => {
          this.setState({ active: false });
          this.props.resetCart();
          this.handleCartInformation(
            "Order submitted succesfully. Please check your email for additional details!"
          );
        },
        error => {
          this.setState({ active: false });
          this.props.resetCart();
          this.handleCartInformation("Something went wrong. Please try again");
        }
      );
  }

  handleComments(e) {
    this.setState({ comments: e.target.value });
  }

  handleCartInformation(information) {
    this.setState({ cartInformation: information });
  }

  render() {
    const cartItems = this.props.cartItems;
    return cartItems.length !== 0 ? (
      <Container className="order-details-container">
        <Card className="order-details-card">
          <Card.Title className="order-details-title">Order Summary</Card.Title>
          <Card.Body>
            {Object.keys(cartItems).map(key => (
              <div className="order-line-items" key={key}>
                {cartItems[key].productName} ({cartItems[key].count}):
                <span className="order-line-value">
                  {cartItems[key].totalValue}
                </span>
              </div>
            ))}
          </Card.Body>
          <Card.Title className="order-line-total">
            <h3>
              Total Tokens:
              <span className="order-line-value">{this.props.totalValue}</span>
            </h3>
          </Card.Title>
          <Button
            className="order-button"
            onClick={this.handleSubmit}
            block
            disabled={this.state.active}
          >
            Complete Order
          </Button>
        </Card>
      </Container>
    ) : (
      <div className="order-details-information">
        {this.state.cartInformation}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartItems: state.cart,
    totalValue: state.totalValue
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetCart: () => {
      dispatch(resetCart());
    },
    removeFromCart: productId => {
      dispatch(removeFromCart(productId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(OrderSubmit);
