import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Alert, Card } from "react-bootstrap";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import axios from "axios";

import { checkCookie, getCookie } from "../../Utils/Utils";
import { raffleList, setOrderList } from "../../../redux/action";
import { PRODUCTS_API, ORDER_API } from "../../../constants/Constants";
import Header from "../../Content/Header/Header";
import PreviewCategories from "../../Content/PreviewCategories/PreviewCategories";

require("./Raffle.scss");

class Raffle extends Component {
  componentDidMount() {
    if (!checkCookie("emailId")) {
      this.setState({ redirect: true });
    } else {
      this.getUserDetails(getCookie("emailId"));
      if (this.props.raffle.length === 0) {
        axios
          .get(PRODUCTS_API + "/" + this.state.pageName.toLowerCase())
          .then(response => {
            this.handleRaffleList(response.data.products);
          });
      }
    }
  }

  handleRaffleList(products) {
    this.props.raffleList(products);
  }

  getUserDetails(email) {
    if (this.props.orders === undefined) {
      axios.get(ORDER_API + "?userId=" + getCookie("userId")).then(response => {
        this.props.setOrderList(response.data.orderList);
      });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      cookie: false,
      pageName: "Raffle",
      raffle: []
    };
  }

  render() {
    const raffle = this.props.raffle;
    return (
      <Container className="categories-page-container">
        <Header />
        <PreviewCategories categoryName={this.state.pageName} />
        <Alert className="alert-message" key="primary" variant="primary">
          The items listed below are available in person on May 7<sup>th</sup>{" "}
          at Resonate.
        </Alert>
        <Container className="food-container">
          {Object.keys(raffle).map(key => (
            <Card className="food-card" key={key}>
              <Card.Img className="food-image" src={raffle[key].imageName} />
              <Card.Body className="food-card-body">
                <Accordion>
                  <AccordionSummary className="food-title">
                    {raffle[key].productName} <ExpandMoreIcon />
                  </AccordionSummary>
                  <AccordionDetails className="food-description">
                    {raffle[key].description}
                  </AccordionDetails>
                </Accordion>
              </Card.Body>
            </Card>
          ))}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    raffle: state ? state.raffle : [],
    user: state ? state.user : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    raffleList: raffle => {
      dispatch(raffleList(raffle));
    },
    setOrderList: orders => {
      dispatch(setOrderList(orders));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(Raffle);
